.Winners {
  background-size: cover;
  padding: 0 0 30px 0;

  .title {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    margin-top: 0;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .table_container {
    background: linear-gradient(180deg, #1b098c 0%, rgba(0, 0, 62, 0) 100%);
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    font-size: 14px;

    h4 {
      font-size: 18px;
      margin: 0;
    }
  }

  .tabsBlock {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs {
    display: flex;
    gap: 10px;

    button {
      box-sizing: border-box;
      border-radius: 8px;
      color: #fafafa;
      background: none;
      display: inline-block;
      padding: 8px 20px;
      cursor: pointer;
      font-weight: bold;
      outline: none;
      border: none;
      box-sizing: border-box;
      margin: 0 2px;
    }

    .active {
      border: 2px solid #28a3ff;
      margin: 0;
    }
  }


  .table {
    padding: 0 20px;
    background: #141461;
    width: 98%;
    border: none;
    border-collapse: collapse;

    th {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #E7EAFF;
      width: 0%;
      padding: 15px 10px;
      background: #2c2cb0;
      text-align: center;
    }

    tr {
      padding: 0 10px;
      margin: 0 10px;
      border-bottom: 1px solid #3434b9;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      padding: 5px 8px;
    }

    a,
    a:visited,
    a:hover,
    a:active {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: relative;
      transition: 0.5s color ease;
      text-decoration: none;
      color: #ffffff;
      border-bottom: 1px solid rgb(132, 111, 255);
      // font-size:2.5em;
    }

    a:hover {
      color: #d73444;
    }

    a.before:before,
    a.after:after {
      content: "";
      transition: 0.5s all ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
    }

    a.before:before {
      top: -0.25em;
    }

    a.after:after {
      bottom: -0.25em;
    }

    a.before:before,
    a.after:after {
      height: 5px;
      height: 0.35rem;
      width: 0;
      background: #d73444;
    }

    a:after {
      left: 0;
    }
  }
}