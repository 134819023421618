.Button {
  background: none;
  color: inherit;
  font: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  width: 200px;
  background: #ffad1d;
  background: linear-gradient(90deg, #ffad1d, #d98b00, #e56813, #e56813, #d98b00, #ffad1d);
  background-size: 400%;
  animation: glowing 20s linear infinite, glow 1.4s linear infinite;
  color: #fff;
  font-size: 1.6em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 34px;
  box-shadow: 0 0 0 0 #4345c0;
  border-radius: 12px;

  &:disabled,
  &[disabled] {
    background: #858585;
    animation: none;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 0 0 #a7a7ff;
    }

    50% {
      box-shadow: 0 0 30px 0 #bfc0ff;
    }
  }
}