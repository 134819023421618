.Header {
  width: 100%;
  z-index: 999;
  background-color: transparent;
  margin-bottom: 20px;

  .header_container {
    padding: 15px 10px;
    border-bottom: 1px solid #4543A9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    color: white;
    font-size: 30px;
    font-weight: 800;
    text-decoration: none;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 15px;
  }

  .navigation {
    margin-bottom: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;
      display: inline-block;
      margin-right: 100px;

      a {
        color: white;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}