.CommonButton {
  color: black;
  font-weight: 700;
  font-size: 16px;
  background: #ffcc42;
  border: none;
  padding: 10px 40px;
  border-radius: 10px;
  cursor: pointer;
  border-bottom: 3px solid #ff860c;
  margin-top: 10px;

  &:active {
    border-bottom: none;
  }
}