$primary-color: #6bfaff;

body {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 18px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  background: url('./assets/banner-bg.png') 60% 50%;
  background-size: cover;
  background-color: #280B70;
  background-repeat: no-repeat;
  color: white;
  height: 100vh;
  text-align: center;
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(44, 14, 61, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #92009b;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slotmachine {
  position: relative;
  /* height: 600px; */
}

.reels {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 70px;
  margin-bottom: 20px;
  
  @media (max-width: 1500px) {
    flex-direction: column;
  }
}

#range-slider .range-slider__thumb[data-lower] {
  width: 0;
}

#range-slider .range-slider__range {
  border-radius: 6px;
}

.spinner-container {
  position: relative;
  overflow: hidden;
  height: 300px;
  display: flex;
  transition: 0.3s transform;
  justify-content: center;
}

.icons-sprite { 
  display: inline-block;
  margin: 0 5px;
  width: 100px;
  overflow: hidden;
  background: url(../public/images/reels/reel_sprite.png) repeat-y;
  will-change: backgroundPosition;
  transition: 5s background-position linear;
  transform: translateZ(0);
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.spinner-container::after {
  position: absolute;
  content: '';
  display: block;
  height: 100px;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  left: 0px;
  background: rgba(255, 0, 0, .1);
}

.win-combinations {
  position: relative;
  border: 1px solid #3a70ff;
  align-self: flex-start;
  padding: 10px 20px;
  border-radius: 10px;
}

.win-combinations img {
  width: 30px;
  height: 30px;
}

.win-combinations tr {
  transition: 5s ease-in-out;
}


.current-win {
  background-color: gold;
}

#range-slider {
  height: 24px;
  border-radius: 12px;
  background: #353644;
  width: 300px;
}

.range-slider__thumb {
  border: 4px solid #fff;
  box-sizing: border-box;
  background: #ab85e3 !important;
}

.range-slider__thumb[data-lower] {
  width: 0;
  margin-left: -30px;
  display: none;
}
.range-slider__range {
  border-radius: 6px;
  border-right: 5px solid transparent;
  left: 0 !important;
  background: linear-gradient(to right, #0073e6, #ee2c2c) !important;
  background-size: 150% 100% !important;
  background-position: 50% 0;
  animation: move-bg .75s infinite linear !important;
}


@keyframes move-bg {
  0% {
    background-position: 50% 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 50% 0;
  }

  75% {
    background-position: 0% 0;
  }

  100% {
    background-position: 50% 0;
  }
}