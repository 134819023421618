body {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 18px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  color: white;
  background: url('../assets/banner-bg.png') 60% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #280B70;
}

.header-section {
  width: 100%;
  z-index: 999;
  background-color: transparent;
}

.header {
  padding: 30px 10px;
  border-bottom: 1px solid #4543A9;
  display: flex;
  justify-content: space-between;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.logo {
  color: white;
  text-decoration: none;
  font-size: 30px;
  font-weight: 700;
}

.title-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: auto;
  // margin-bottom: -40px;

  @media (max-width: 700px) {
    line-height: 50px;
  }
}

.slotmachine-container {
  display: block;
  border-radius: 10px;
  border: 2px dashed #3e25a0;
  padding: 10px;
}

.flex-wrap {
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
}

.main-title {
  text-align: center;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 76px;
  font-weight: 600;

  @media (max-width: 700px) {
    font-size: 50px;
  }
}

.main-deposit {
  text-align: center;

  @media (max-width: 700px) {
    font-size: 25px;
  }
}

.main-content {
  height: 80vh;
  background-size: cover;
}

.cir-1, .cir-2, .cir-3, .cir-4 {
  opacity: 0.3;
  z-index: -20;
}

.cir-1 {

  width: 200px;
  top: 40%;
  left: 20%;
  position: absolute;
  -webkit-animation: floatingLeft 4s linear infinite;
  animation: floatingLeft 3s linear infinite;
}

.cir-2 {
  z-index: -20;
  width: 300px;
  top: 60%;
  left: 10%;
  position: absolute;
  -webkit-animation: floatingLeft 4s linear infinite;
  animation: floatingLeft 3s linear infinite;
}

.cir-3 {
  z-index: -20;
  width: 200px;
  top: 40%;
  right: 20%;
  position: absolute;
  -webkit-animation: floatingRight 4s linear infinite;
  animation: floatingRight 3s linear infinite;
}

.cir-4 {
  z-index: -20;
  width: 300px;
  top: 60%;
  right: 10%;
  position: absolute;
  -webkit-animation: floatingRight 4s linear infinite;
  animation: floatingRight 3s linear infinite;
}

@keyframes floatingRight {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  33% {
    -webkit-transform: translate(5px, -5px);
    transform: translate(5px, -5px);
  }
  66% {
    -webkit-transform: translate(-5px, 5px);
    transform: translate(-5px, 5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes floatingLeft {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  33% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  66% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}